import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionSeven.css"
import { db } from '../../firebase';
import AdminDRSQNA from './AdminDRSQNA';

const AdminSectionSeven = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [changeSubtitle, setChangeSubtitle] = useState("")
    const [changeSubtitleColor, setChangeSubtitleColor] = useState("")
    const [qna, setQna] = useState([])
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [changeSubtitleFont, setChangeSubtitleFont] = useState("")
    const [addNewQNA, setAddNewQNA] = useState(false)
    const [newQuestion, setNewQuestion] = useState("")
    const [newAnswer, setNewAnswer] = useState("")

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applySubtitle = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").update({
            subtitle: changeSubtitle
        })
        ref1.current.value = ""
    }

    const applySubtitleColor = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").update({
            subtitleColor: changeSubtitleColor
        })
    }

    const applySubtitleFont = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").update({
            subtitleFont: changeSubtitleFont
        })
    }

    const applyTitle = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").update({
            title: changeTitle
        })
        ref2.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").update({
            titleFont: changeTitleFont
        })
    }

    const addNew = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").add({
            question: newQuestion,
            answer: newAnswer
        })
        ref3.current.value = ""
        ref4.current.value = ""
    }

    useEffect(() => {
        db.collection("FAQ").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").onSnapshot(snapshot => {
            setQna(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <div className="delete-reviews-section-seven" >
            {order.map((order, index) => {
                if (order.data.pageTitle === "FAQ")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="admin-seven-title">
                <div className="admin-seven-subtitle">
                    <div className="change-font">
                        <select name="" id="" onChange={(e) => setChangeSubtitleFont(e.target.value)}>
                            {fonts.map((font, index) => {
                                return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                            })}
                        </select>
                        <button className="apply-button" onClick={applySubtitleFont}>Apply</button>
                    </div>
                    <div className="change-color">
                        <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeSubtitleColor(e.target.value)} />
                        <button className="apply-button" onClick={applySubtitleColor}>Apply</button>
                    </div>
                    <input ref={ref1} type="text" placeholder="Change subtitle here" onChange={(e) => setChangeSubtitle(e.target.value)} />
                    <button className="apply-button" onClick={applySubtitle}>Apply</button>
                    {title.map((subtitle, index) => {
                        return <span style={{ color: subtitle.data.subtitleColor, fontFamily: subtitle.data.subtitleFont }} key={index}>{subtitle.data.subtitle}</span>
                    }
                    )}
                </div>
                <div className="admin-seven-main-title">
                    <div className="change-font">
                        <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                            {fonts.map((font, index) => {
                                return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                            })}
                        </select>
                        <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                    </div>
                    <div className="change-color">
                        <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                        <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                    </div>
                    <input ref={ref2} type="text" placeholder="Change title here" onChange={(e) => setChangeTitle(e.target.value)} />
                    <button className="apply-button" onClick={applyTitle}>Apply</button>
                    {title.map((title, index) => {
                        return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }} key={index}>{title.data.title}</h1>
                    }
                    )}
                </div>
            </div>
            <div className="add-new-qna">
                <button className="apply-button" onClick={() => setAddNewQNA(true)}>Add new question and answer</button>
                {addNewQNA &&
                    <div className="add-new-qna-inputs">
                        <input ref={ref3} type="text" placeholder='Enter question' onChange={(e) => setNewQuestion(e.target.value)} />
                        <input ref={ref4} type="text" placeholder='Enter answer' onChange={(e) => setNewAnswer(e.target.value)} />
                        <div className="add-new-qna-buttons">
                            <button className="apply-button" onClick={addNew}>Add</button>
                            <button className="apply-button" onClick={() => setAddNewQNA(false)}>Close</button>
                        </div>
                    </div>
                }
            </div>
            <div className="drs-seven-qna">
                {qna.map((qna, index) => {
                    return <AdminDRSQNA q={qna.data.question} a={qna.data.answer} id={qna.id} key={index} qColor={qna.data.questionColor} aColor={qna.data.answerColor} qFont={qna.data.questionFont} aFont={qna.data.answerFont} />
                })}
            </div>
        </div>
    )
}

export default AdminSectionSeven