import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionSix.css"
import { db } from '../../firebase';
import AdminDRSCarousel from './AdminDRSCarousel';

const AdminSectionSix = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")

    const ref1 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
            titleFont: changeTitleFont
        })
    }

    useEffect(() => {
        db.collection("Feedback").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <div className="delete-reviews-section-six">
            {order.map((order, index) => {
                if (order.data.pageTitle === "Feedback")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="admin-section-six-title">
                <div className="change-font">
                    <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                        {fonts.map((font, index) => {
                            return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                        })}
                    </select>
                    <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                </div>
                <div className="change-color">
                    <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                    <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                </div>
                <label>Change title to:</label>
                <input ref={ref1} type="text" placeholder="Enter title here" onChange={(e) => setChangeTitle(e.target.value)} />
                <button className="apply-button" onClick={applyTitle}>Apply</button>
                {title.map((title, index) => {
                    return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }} key={index}>{title.data.title}</h3>
                })}
            </div>
            <div className="drs-six-comments">
                <AdminDRSCarousel />
            </div>
        </div>
    )
}

export default AdminSectionSix