import React, { useEffect, useRef, useState } from 'react'
import "../styles/DeleteReviews.css"
import TopBar from './TopBar'
import DeleteReviewsSectionOne from "../components/DeleteReviewsSectionOne"
import DeleteReviewsSectionTwo from "../components/DeleteReviewsSectionTwo"
import DeleteReviewsSectionThree from "../components/DeleteReviewsSectionThree"
import DeleteReviewsSectionFour from "../components/DeleteReviewsSectionFour"
import DeleteReviewsSectionFive from "../components/DeleteReviewsSectionFive"
import DeleteReviewsSectionSix from "../components/DeleteReviewsSectionSix"
import DeleteReviewsSectionSeven from "../components/DeleteReviewsSectionSeven"
import DeleteReviewsSectionEight from "../components/DeleteReviewsSectionEight"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Footer from './Footer'
import { db } from '../firebase'
import CookiesConsent from './CookiesConsent'


const DeleteReviews = () => {

    const [order, setOrder] = useState([])

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref7 = useRef()
    const ref8 = useRef()

    useEffect(() => {
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <div className="delete-reviews">
            <TopBar refTop={ref1} refAboutUs={ref2} refWhyUs={ref8} refService={ref3} refPrice={ref4} refSequence={ref5} refFeedback={ref6} refFAQ={ref7} />
            {order.map((order) => {
                const pageTitle = order.data.pageTitle;

                switch (pageTitle) {
                    case "Home":
                        return <DeleteReviewsSectionOne key={1} refer={ref1} />;
                    case "Price":
                        return <DeleteReviewsSectionFour key={4} refer={ref4} refTop={ref1} />;
                    case "AboutUs":
                        return <DeleteReviewsSectionTwo key={2} refer={ref2} />;
                    case "WhyUs":
                        return <DeleteReviewsSectionEight key={8} refer={ref8} />;
                    case "Service":
                        return <DeleteReviewsSectionThree key={3} refer={ref3} />;
                    case "Sequence":
                        return <DeleteReviewsSectionFive key={5} refer={ref5} />;
                    case "Feedback":
                        return <DeleteReviewsSectionSix key={6} refer={ref6} />;
                    case "FAQ":
                        return <DeleteReviewsSectionSeven key={7} refer={ref7} />;
                    case "Footer":
                        return <Footer key={99} />;
                    default:
                        return null;
                }
            })}
            <CookiesConsent />
            <a href="tel:+4915219457172" className="contact-phone">
                <span>Direkt Anrufen</span>
                <LocalPhoneIcon />
            </a>
        </div>
    )
}

export default DeleteReviews