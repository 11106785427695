import React, { useState } from 'react'
import "../styles/DRSQNA.css"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const DRSQNA = ({ q, a, qColor, aColor, qFont, aFont }) => {

    const [expanded, setExpanded] = useState(false)

    return (
        <div className="drsqna">
            <div className="drs-q" onClick={() => setExpanded(!expanded)}>
                <span style={{ color: qColor, fontFamily: qFont }}>{q}</span>
                {expanded ? <RemoveIcon /> : <AddIcon />}
            </div>
            {expanded &&
                <p style={{ color: aColor, font: aFont }}>{a}</p>
            }
        </div>
    )
}

export default DRSQNA