import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarRatings from 'react-star-ratings';
import "../../styles/admin styles/AdminDRSCarousel.css"
import { db } from '../../firebase';

const AdminDRSCarousel = () => {

    const [openForm, setOpenForm] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [newReviewRating, setNewReviewRating] = useState("")
    const [newReviewText, setNewReviewText] = useState("")
    const [newReviewName, setNewReviewName] = useState("")
    const [newReviewJob, setNewReviewJob] = useState("")
    const [comments, setComments] = useState([])
    const [settings, setSettings] = useState()
    const [changeTextColor, setChangeTextColor] = useState("")
    const [changeStarsColor, setChangeStarsColor] = useState("")
    const [starSize, setStarSize] = useState("")
    const [changeRating, setChangeRating] = useState(0)
    const [changeText, setChangeText] = useState("")
    const [changeName, setChangeName] = useState("")
    const [changeJob, setChangeJob] = useState("")
    const [fonts, setFonts] = useState([])
    const [changeTextFont, setChangeTextFont] = useState("")
    const [changePersonFont, setChangePersonFont] = useState("")

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref7 = useRef()
    const ref8 = useRef()

    const addNewReview = (e) => {
        e.preventDefault()
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").add({
            rating: newReviewRating,
            job: newReviewJob,
            name: newReviewName,
            text: newReviewText,
            starsColor: "#fbbc04",
            textColor: "#ffffff"
        })
        ref1.current.value = ""
        ref2.current.value = ""
        ref3.current.value = ""
        ref4.current.value = ""
    }

    const deleteReview = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).delete()
        setOpenDelete(false)
    }

    const applyTextColor = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            textColor: changeTextColor
        })
    }

    const applyTextFont = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            textFont: changeTextFont
        })
    }

    const applyPersonFont = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            personFont: changePersonFont
        })
    }


    const applyStarsColor = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            starsColor: changeStarsColor
        })
    }

    const applyRating = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            rating: changeRating
        })
        ref5.current.value = ""
    }

    const applyText = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            text: changeText
        })
        ref6.current.value = ""
    }

    const applyName = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            name: changeName
        })
        ref7.current.value = ""
    }

    const applyJob = (id) => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").doc(id).update({
            job: changeJob
        })
        ref8.current.value = ""
    }

    useEffect(() => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").onSnapshot(snapshot => {
            setComments(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });

        const windowWidth = () => {
            if (window.outerWidth < 561) {
                setSettings(settingsMob)
                setStarSize("25px")
            } else {
                setSettings(settingsDef)
                setStarSize("40px")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    const settingsDef = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const settingsMob = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="drs-carousel">
            <div className="add-new-review">
                <button className="apply-button" onClick={() => setOpenForm(true)}>Add New Review</button>
            </div>
            {openForm &&
                <div className="add-new-comment-form">
                    <form onSubmit={addNewReview}>
                        <button className="close-button" onClick={() => setOpenForm(false)}>X</button>
                        <input ref={ref1} type="text" placeholder="Enter rating" onChange={(e) => setNewReviewRating(e.target.value)} required />
                        <textarea ref={ref2} rows={4} type="text" placeholder="Enter text" onChange={(e) => setNewReviewText(e.target.value)} required />
                        <input ref={ref3} type="text" placeholder="Enter name" onChange={(e) => setNewReviewName(e.target.value)} required />
                        <input ref={ref4} type="text" placeholder="Enter job title" onChange={(e) => setNewReviewJob(e.target.value)} required />
                        <button className="apply-button" type="submit">Apply</button>
                    </form>
                </div>
            }
            <Slider {...settings}>
                {comments.map((comment, index) => (
                    <div className="admin-drs-carousel-comment" key={index}>
                        <div className="delete-review-carousel">
                            <button className="delete-button" onClick={() => setOpenDelete(true)}>Delete</button>
                            {openDelete &&
                                <div className="are-you-sure-to-delete">
                                    <span>Are you sure you want to delete this post?</span>
                                    <div className="aystd-buttons">
                                        <button onClick={() => deleteReview(comment.id)}>Yes</button>
                                        <button onClick={() => setOpenDelete(false)}>No</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="admin-comment">
                            <span>Text font: </span>
                            <div className="change-font">
                                <select name="" id="" onChange={(e) => setChangeTextFont(e.target.value)}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" onClick={() => applyTextFont(comment.id)}>Apply</button>
                            </div>
                            <span>Person font: </span>
                            <div className="change-font" style={{ marginBottom: "1rem" }}>
                                <select name="" id="" onChange={(e) => setChangePersonFont(e.target.value)}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" onClick={() => applyPersonFont(comment.id)}>Apply</button>
                            </div>
                            <div className="change-color">
                                <label>Change text color</label>
                                <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTextColor(e.target.value)} />
                                <button className="apply-button" onClick={() => applyTextColor(comment.id)}>Apply</button>
                            </div>
                            <div className="change-color">
                                <label>Change stars color</label>
                                <input type="color" defaultValue="#fbbc04" onChange={(e) => setChangeStarsColor(e.target.value)} />
                                <button className="apply-button" onClick={() => applyStarsColor(comment.id)}>Apply</button>
                            </div>
                            <div className="admin-comment-input">
                                <input ref={ref5} type="number" min="0" max="5" placeholder="Change rating here" onChange={(e) => setChangeRating(e.target.value)} />
                                <button className="apply-button" onClick={() => applyRating(comment.id)}>Apply</button>
                            </div>
                            <div className="admin-comment-input">
                                <textarea ref={ref6} rows={3} type="text" placeholder="Change text here" onChange={(e) => setChangeText(e.target.value)} />
                                <button className="apply-button" onClick={() => applyText(comment.id)}>Apply</button>
                            </div>
                            <div className="admin-comment-input">
                                <input ref={ref7} type="text" placeholder="Change name here" onChange={(e) => setChangeName(e.target.value)} />
                                <button className="apply-button" onClick={() => applyName(comment.id)}>Apply</button>
                            </div>
                            <div className="admin-comment-input">
                                <input ref={ref8} type="text" placeholder="Job title" onChange={(e) => setChangeJob(e.target.value)} />
                                <button className="apply-button" onClick={() => applyJob(comment.id)}>Apply</button>
                            </div>
                        </div>
                        <div className="drs-carousel-comment-content">
                            <div className="drs-carousel-comment-rating">
                                <span style={{ color: comment.data.textColor }} className="rating">{comment.data.rating}</span>
                                <div className="rating-stars">
                                    <StarRatings rating={parseFloat(comment.data.rating)} starRatedColor={comment.data.starsColor} starEmptyColor="white" starDimension={starSize} starSpacing="3px" />
                                </div>
                            </div>
                            <p style={{ color: comment.data.textColor, fontFamily: comment.data.textFont }} className="drs-carousel-comment-text">{comment.data.text}</p>
                            <div className="drs-carousel-comment-user">
                                <div className="drs-carousel-comment-name" style={{ fontFamily: comment.data.personFont }}>
                                    <p>{comment.data.name}</p>
                                    <span>{comment.data.job}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default AdminDRSCarousel