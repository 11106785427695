import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionFive.css"
import { db } from '../../firebase';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import DoneIcon from '@mui/icons-material/Done';

const AdminSectionFive = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [changeBoxOneTextColor, setChangeBoxOneTextColor] = useState("")
    const [changeBoxOneTitleColor, setChangeBoxOneTitleColor] = useState("")
    const [changeBoxOneTitleFont, setChangeBoxOneTitleFont] = useState("")
    const [changeBoxOneTextFont, setChangeBoxOneTextFont] = useState("")
    const [changeBoxTwoTextColor, setChangeBoxTwoTextColor] = useState("")
    const [changeBoxTwoTitleColor, setChangeBoxTwoTitleColor] = useState("")
    const [changeBoxTwoTextFont, setChangeBoxTwoTextFont] = useState("")
    const [changeBoxTwoTitleFont, setChangeBoxTwoTitleFont] = useState("")
    const [changeBoxThreeTextColor, setChangeBoxThreeTextColor] = useState("")
    const [changeBoxThreeTitleColor, setChangeBoxThreeTitleColor] = useState("")
    const [changeBoxThreeTitleFont, setChangeBoxThreeTitleFont] = useState("")
    const [changeBoxThreeTextFont, setChangeBoxThreeTextFont] = useState("")
    const [stepOne, setStepOne] = useState([]);
    const [changeStepOneTitle, setChangeStepOneTitle] = useState("");
    const [changeStepOneText, setChangeStepOneText] = useState("");
    const [stepTwo, setStepTwo] = useState([]);
    const [changeStepTwoTitle, setChangeStepTwoTitle] = useState("");
    const [changeStepTwoText, setChangeStepTwoText] = useState("");
    const [stepThree, setStepThree] = useState([]);
    const [changeStepThreeTitle, setChangeStepThreeTitle] = useState("");
    const [changeStepThreeText, setChangeStepThreeText] = useState("");
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref7 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").set({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").update({
            titleFont: changeTitleFont
        })
    }

    const applyStepOneTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc("TFWeUJFpYzZBoqEkBsqO").update({
            title: changeStepOneTitle
        })
        ref2.current.value = ""
    }

    const applyStepOneText = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc("TFWeUJFpYzZBoqEkBsqO").update({
            text: changeStepOneText
        })
        ref3.current.value = ""
    }

    const applyBoxOneTitleColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            boxTitleColor: changeBoxOneTitleColor
        })
    }

    const applyBoxOneTitleFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            boxTitleFont: changeBoxOneTitleFont
        })
    }

    const applyBoxOneTextColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            textColor: changeBoxOneTextColor
        })
    }

    const applyBoxOneTextFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            boxTextFont: changeBoxOneTextFont
        })
    }

    const applyBoxTwoTitleColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            boxTitleColor: changeBoxTwoTitleColor
        })
    }

    const applyBoxTwoTextColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            textColor: changeBoxTwoTextColor
        })
    }

    const applyBoxTwoTitleFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            boxTitleFont: changeBoxTwoTitleFont
        })
    }

    const applyBoxTwoTextFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            boxTextFont: changeBoxTwoTextFont
        })
    }

    const applyBoxThreeTitleColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            boxTitleColor: changeBoxThreeTitleColor
        })
    }

    const applyBoxThreeTextColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            textColor: changeBoxThreeTextColor
        })
    }

    const applyBoxThreeTitleFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            boxTitleFont: changeBoxThreeTitleFont
        })
    }

    const applyBoxThreeTextFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            boxTextFont: changeBoxThreeTextFont
        })
    }

    const applyStepTwoTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc("v2m3RKMv6LDOREcZyrN8").update({
            title: changeStepTwoTitle
        })
        ref4.current.value = ""
    }

    const applyStepTwoText = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc("v2m3RKMv6LDOREcZyrN8").update({
            text: changeStepTwoText
        })
        ref5.current.value = ""
    }

    const applyStepThreeTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc("jSjlK8v2JNBZs285RVnj").update({
            title: changeStepThreeTitle
        })
        ref6.current.value = ""
    }

    const applyStepThreeText = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc("jSjlK8v2JNBZs285RVnj").update({
            text: changeStepThreeText
        })
        ref7.current.value = ""
    }

    useEffect(() => {
        db.collection("Sequence").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").onSnapshot(snapshot => {
            setStepOne(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").onSnapshot(snapshot => {
            setStepTwo(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").onSnapshot(snapshot => {
            setStepThree(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <div className="delete-reviews-section-five">
            {order.map((order, index) => {
                if (order.data.pageTitle === "Sequence")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="change-title-sequence">
                <div className="change-font">
                    <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                        {fonts.map((font, index) => {
                            return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                        })}
                    </select>
                    <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                </div>
                <div className="change-color">
                    <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                    <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                </div>
                <label>Change title to:</label>
                <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                <button className="apply-button" onClick={applyTitle}>Apply</button>
                {title.map((title, index) => {
                    return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }} key={index}>{title.data.title}</h1>
                })}
            </div>
            <div className="drs-five-steps">
                <div className="drs-five-step admin-step">
                    <div className="drs-five-step-top admin-step-top">
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Title font: </span>
                        <div className="change-font">
                            <select name="" id="" onChange={(e) => setChangeBoxOneTitleFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={() => applyBoxOneTitleFont("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                        </div>
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Text font: </span>
                        <div className="change-font">
                            <select name="" id="" onChange={(e) => setChangeBoxOneTextFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={() => applyBoxOneTextFont("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                        </div>
                        <div className="change-color afcc" style={{ marginTop: "1rem" }}>
                            <label>Change title color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxOneTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxOneTitleColor("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                        </div>
                        <div className="change-color afcc">
                            <label>Change text color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxOneTextColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxOneTextColor("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                        </div>
                        <span><span className="step-num">1</span> <BusinessCenterIcon sx={{ fontSize: 30 }} /></span>
                        <input ref={ref2} type="text" placeholder="Change step title" onChange={(e) => setChangeStepOneTitle(e.target.value)} />
                        <button className="apply-button" onClick={applyStepOneTitle}>Apply</button>
                        {stepOne.map((step, index) => {
                            return (
                                <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont }} key={index}>{step.data.title}</p>
                            )
                        })}
                    </div>
                    <textarea ref={ref3} rows={3} type="text" placeholder="Change step text" onChange={(e) => setChangeStepOneText(e.target.value)} />
                    <button className="apply-button" onClick={applyStepOneText}>Apply</button>
                    {stepOne.map((step, index) => {
                        return (
                            <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont }} key={index}>{step.data.text}</p>
                        )
                    })}
                </div>
                <div className="drs-five-step admin-step">
                    <div className="drs-five-step-top admin-step-top">
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Title font: </span>
                        <div className="change-font">
                            <select name="" id="" onChange={(e) => setChangeBoxTwoTitleFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={() => applyBoxTwoTitleFont("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                        </div>
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Text font: </span>
                        <div className="change-font">
                            <select name="" id="" onChange={(e) => setChangeBoxTwoTextFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={() => applyBoxTwoTextFont("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                        </div>
                        <div className="change-color afcc" style={{ marginTop: "1rem" }}>
                            <label>Change title color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxTwoTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxTwoTitleColor("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                        </div>
                        <div className="change-color afcc">
                            <label>Change text color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxTwoTextColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxTwoTextColor("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                        </div>
                        <span><span className="step-num">2</span> <AutoDeleteIcon sx={{ fontSize: 30 }} /></span>
                        <input ref={ref4} type="text" placeholder="Change step title" onChange={(e) => setChangeStepTwoTitle(e.target.value)} />
                        <button className="apply-button" onClick={applyStepTwoTitle}>Apply</button>
                        {stepTwo.map((step, index) => {
                            return (
                                <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont }} key={index}>{step.data.title}</p>
                            )
                        })}
                    </div>
                    <textarea ref={ref5} rows={3} type="text" placeholder="Change step text" onChange={(e) => setChangeStepTwoText(e.target.value)} />
                    <button className="apply-button" onClick={applyStepTwoText}>Apply</button>
                    {stepTwo.map((step, index) => {
                        return (
                            <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont }} key={index}>{step.data.text}</p>
                        )
                    })}
                </div>
                <div className="drs-five-step admin-step">
                    <div className="drs-five-step-top admin-step-top">
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Title font: </span>
                        <div className="change-font">
                            <select name="" id="" onChange={(e) => setChangeBoxThreeTitleFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={() => applyBoxThreeTitleFont("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                        </div>
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Text font: </span>
                        <div className="change-font">
                            <select name="" id="" onChange={(e) => setChangeBoxThreeTextFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={() => applyBoxThreeTextFont("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                        </div>
                        <div className="change-color afcc" style={{ marginTop: "1rem" }}>
                            <label>Change title color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxThreeTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxThreeTitleColor("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                        </div>
                        <div className="change-color afcc">
                            <label>Change text color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxThreeTextColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxThreeTextColor("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                        </div>
                        <span><span className="step-num">3</span> <DoneIcon sx={{ fontSize: 30 }} /></span>
                        <input ref={ref6} type="text" placeholder="Change step title" onChange={(e) => setChangeStepThreeTitle(e.target.value)} />
                        <button className="apply-button" onClick={applyStepThreeTitle}>Apply</button>
                        {stepThree.map((step, index) => {
                            return (
                                <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont }} key={index}>{step.data.title}</p>
                            )
                        })}
                    </div>
                    <textarea ref={ref7} rows={3} type="text" placeholder="Change step text" onChange={(e) => setChangeStepThreeText(e.target.value)} />
                    <button className="apply-button" onClick={applyStepThreeText}>Apply</button>
                    {stepThree.map((step, index) => {
                        return (
                            <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont }} key={index}>{step.data.text}</p>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AdminSectionFive