import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const CookiesConsent = () => {

    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 });
        setShowBanner(false);
    };

    const handleDecline = () => {
        Cookies.set('cookieConsent', 'declined', { expires: 365 });
        setShowBanner(false);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div className="cookies">
            <h3>Cookie-Zustimmung verwalten</h3>
            <p className="cookie-text">Um dir ein optimales Erlebnis zu bieten, verwenden wir Technologien wie Cookies, um Geräteinformationen zu speichern und/oder darauf zuzugreifen. Wenn du diese Technologien zustimmst, können wir Daten wie das Surfverhalten oder eindeutige IDs auf dieser Website verarbeiten. Wenn du deine Zustimmung nicht erteilst oder zurückziehst, können bestimmte Merkmale und Funktionen beeinträchtigt werden.</p>
            <div className="cookie-buttons">
                <button className="cookie-accept ifb" onClick={handleAccept}>AKZEPTIEREN</button>
                <button className="ifb" onClick={handleDecline}>ABLEHNEN</button>
            </div>
            <div className="cookie-links">
                <Link to='/datenschutz'>Datenschutz</Link>
                <Link to='/impressum'>Impressum</Link>
            </div>
        </div>
    )
}

export default CookiesConsent