import React, { useState } from 'react'
import "../../styles/admin styles/AdminLogIn.css"
import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from '../../firebase';
import { Link } from 'react-router-dom';

const AdminLogIn = () => {

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const login = async (e) => {
    e.preventDefault()
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
    } catch (error) {
      alert(error.message)
    }
  }

  return (
    <div className="admin-login">
      <form className="login-card" onSubmit={login}>
        <input type="email" onChange={(e) => { setLoginEmail(e.target.value) }} placeholder="Email" />
        <input type="password" onChange={(e) => { setLoginPassword(e.target.value) }} placeholder="Password" />
        <button type="submit">LogIn</button>
        <Link to="/">Back to main page</Link>
      </form>
    </div>
  )
}

export default AdminLogIn