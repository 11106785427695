import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionTwo.css"
import { db } from '../../firebase';

const AdminSectionTwo = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [text, setText] = useState([])
    const [changeText, setChangeText] = useState("")
    const [changeTextColor, setChangeTextColor] = useState("")
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [changeTextFont, setChangeTextFont] = useState("")

    const ref1 = useRef()
    const ref2 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").update({
            titleFont: changeTitleFont
        })
    }

    const applyTextFont = (id) => {
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").collection("text").doc(id).update({
            textFont: changeTextFont
        })
    }

    const applyText = (id) => {
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").collection("text").doc(id).set({
            text: changeText
        })
        ref2.current.value = ""
    }

    const applyTextColor = (id) => {
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").collection("text").doc(id).update({
            textColor: changeTextColor
        })
    }

    useEffect(() => {
        db.collection("AboutUs").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").collection("text").onSnapshot(snapshot => {
            setText(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])


    return (
        <div className="delete-reviews-section-two">
            {order.map((order, index) => {
                if (order.data.pageTitle === "AboutUs")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="change-title-wrap">
                <div className="change-font">
                    <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                        {fonts.map((font, index) => {
                            return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                        })}
                    </select>
                    <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                </div>
                <div className="change-title">
                    <div className="change-color">
                        <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                        <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                    </div>
                    <label>Change title to:</label>
                    <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                    <button className="apply-button" onClick={applyTitle}>Apply</button>
                </div>
                {title.map((title, index) => {
                    return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }} key={index}>{title.data.title}</h1>

                })}
            </div>
            {text.map((text, index) => {
                return <div className="change-text-about-us" key={index}>
                    <div className="change-font">
                        <select name="" id="" onChange={(e) => setChangeTextFont(e.target.value)}>
                            {fonts.map((font, index) => {
                                return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                            })}
                        </select>
                        <button className="apply-button" onClick={() => applyTextFont(text.id)}>Apply</button>
                    </div>
                    <div className="change-color">
                        <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTextColor(e.target.value)} />
                        <button className="apply-button" onClick={() => applyTextColor(text.id)}>Apply</button>
                    </div>
                    <label>Change text to:</label>
                    <textarea ref={ref2} rows={4} type="text" onChange={(e) => setChangeText(e.target.value)} placeholder="Enter text here" />
                    <button className="apply-button" onClick={() => applyText(text.id)}>Apply</button>
                    <p style={{ color: text.data.textColor, fontFamily: text.data.textFont }} className="admin-about-us-text">{text.data.text}</p>
                </div>
            })}
        </div>
    )
}

export default AdminSectionTwo