import React, { useEffect, useState } from 'react'
import "../styles/TopBar.css"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ShieldIcon from '@mui/icons-material/Shield';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ForumIcon from '@mui/icons-material/Forum';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { db } from '../firebase';

const TopBar = ({ refTop, refAboutUs, refWhyUs, refService, refPrice, refSequence, refFeedback, refFAQ }) => {

    const [openNav, setOpenNav] = useState(false)
    const [mobileNav, setMobileNav] = useState(null)

    const [order, setOrder] = useState([])
    const [home, setHome] = useState([]);
    const [price, setPrice] = useState([]);
    const [aboutUs, setAboutUs] = useState([]);
    const [whyUs, setWhyUs] = useState([]);
    const [service, setService] = useState([]);
    const [sequence, setSequence] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [faq, setFaq] = useState([]);

    useEffect(() => {
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("home").onSnapshot(snapshot => {
            setHome(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("price").onSnapshot(snapshot => {
            setPrice(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("about us").onSnapshot(snapshot => {
            setAboutUs(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("why us").onSnapshot(snapshot => {
            setWhyUs(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("service").onSnapshot(snapshot => {
            setService(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("sequence").onSnapshot(snapshot => {
            setSequence(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("feedback").onSnapshot(snapshot => {
            setFeedback(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("faq").onSnapshot(snapshot => {
            setFaq(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });

        const windowWidth = () => {
            if (window.outerWidth < 930) {
                setMobileNav(true)
            } else {
                setMobileNav(false)
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="top-bar">
            <div className="top-bar-logo">
                <img src="https://firebasestorage.googleapis.com/v0/b/deinprofil-5ebb7.appspot.com/o/wldb.com%20Logo%20white-yellow.png?alt=media&token=b1fa354d-3f4b-4b05-bf81-89795367a40c" alt="" />
            </div>
            {mobileNav ?
                <div className='top-bar-nav-mob'>
                    <span className="nav-button" onClick={() => { setOpenNav(!openNav) }}>
                        {openNav ? <CloseIcon /> : <MenuIcon />}
                    </span>
                    {openNav &&
                        <ul>
                            {order.map((order, i) => {
                                if (order.data.pageTitle === "Home")
                                    return <>
                                        {home.map((home, index) => {
                                            return <li key={index} onClick={() => { refTop.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><HomeIcon />{home.data.text}</span></li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Price")
                                    return <>
                                        {price.map((price, index) => {
                                            return <li key={index} onClick={() => { refPrice.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><LocalOfferIcon />{price.data.text}</span></li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "AboutUs")
                                    return <>
                                        {aboutUs.map((about, index) => {
                                            return <li key={index} onClick={() => { refAboutUs.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><InfoIcon />{about.data.text}</span></li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "WhyUs")
                                    return <>
                                        {whyUs.map((why, index) => {
                                            return <li key={index} onClick={() => { refWhyUs.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><PeopleAltIcon />{why.data.text}</span></li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Service")
                                    return <>
                                        {service.map((service, index) => {
                                            return <li key={index} onClick={() => { refService.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><ShieldIcon />{service.data.text}</span></li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Sequence")
                                    return <>
                                        {sequence.map((sequence, index) => {
                                            return <li key={index} onClick={() => { refSequence.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><FormatListNumberedIcon />{sequence.data.text}</span></li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Feedback")
                                    return <>
                                        {feedback.map((feedback, index) => {
                                            return <li key={index} onClick={() => { refFeedback.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><ForumIcon />{feedback.data.text}</span></li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "FAQ")
                                    return <>
                                        {faq.map((faq, index) => {
                                            return <li key={index} onClick={() => { refFAQ.current.scrollIntoView({ behavior: 'smooth' }); setOpenNav(false) }}><span><QuestionMarkIcon />{faq.data.text}</span></li>
                                        })}
                                    </>
                            })}
                        </ul>
                    }
                </div> :
                <ul className="top-bar-nav">
                    {order.map((order, i) => {
                        if (order.data.pageTitle === "Home")
                            return <>
                                {home.map((home, index) => {
                                    return <li key={index} onClick={() => { refTop.current.scrollIntoView({ behavior: 'smooth' }) }}>{home.data.text}</li>
                                })}
                            </>
                        if (order.data.pageTitle === "Price")
                            return <>
                                {price.map((price, index) => {
                                    return <li key={index} onClick={() => { refPrice.current.scrollIntoView({ behavior: 'smooth' }) }}>{price.data.text}</li>
                                })}
                            </>
                        if (order.data.pageTitle === "AboutUs")
                            return <>
                                {aboutUs.map((about, index) => {
                                    return <li key={index} onClick={() => { refAboutUs.current.scrollIntoView({ behavior: 'smooth' }) }}>{about.data.text}</li>
                                })}
                            </>
                        if (order.data.pageTitle === "WhyUs")
                            return <>
                                {whyUs.map((why, index) => {
                                    return <li key={index} onClick={() => { refWhyUs.current.scrollIntoView({ behavior: 'smooth' }) }}>{why.data.text}</li>
                                })}
                            </>
                        if (order.data.pageTitle === "Service")
                            return <>
                                {service.map((service, index) => {
                                    return <li key={index} onClick={() => { refService.current.scrollIntoView({ behavior: 'smooth' }) }}>{service.data.text}</li>
                                })}
                            </>
                        if (order.data.pageTitle === "Sequence")
                            return <>
                                {sequence.map((sequence, index) => {
                                    return <li key={index} onClick={() => { refSequence.current.scrollIntoView({ behavior: 'smooth' }) }}>{sequence.data.text}</li>
                                })}
                            </>
                        if (order.data.pageTitle === "Feedback")
                            return <>
                                {feedback.map((feedback, index) => {
                                    return <li key={index} onClick={() => { refFeedback.current.scrollIntoView({ behavior: 'smooth' }) }}>{feedback.data.text}</li>
                                })}
                            </>
                        if (order.data.pageTitle === "FAQ")
                            return <>
                                {faq.map((faq, index) => {
                                    return <li key={index} onClick={() => { refFAQ.current.scrollIntoView({ behavior: 'smooth' }) }}>{faq.data.text}</li>
                                })}
                            </>
                    })}
                </ul>
            }
        </div>
    )
}

export default TopBar