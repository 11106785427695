import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionSix.css"
import DRSCarousel from './DRSCarousel'
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionSix = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([])

    useEffect(() => {
        db.collection("Feedback").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-six" ref={ref}>
                {title.map((title, index) => {
                    return <h3 key={index} className={inView ? "fade-right" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }}>{title.data.title}</h3>
                })}
                <div className={`drs-six-comments ${inView ? "fade-left" : ""}`}>
                    <DRSCarousel />
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionSix