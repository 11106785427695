import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionSeven.css"
import DRSQNA from './DRSQNA'
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionSeven = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([]);
    const [qna, setQna] = useState([])

    useEffect(() => {
        db.collection("FAQ").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").onSnapshot(snapshot => {
            setQna(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-seven" ref={ref}>
                {title.map((title, index) => {
                    return <div key={index} className={`drs-seven-title ${inView ? "fade-left" : ""}`}>
                        <span style={{ color: title.data.subtitleColor, fontFamily: title.data.subtitleFont }}>{title.data.subtitle}</span>
                        <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }}>{title.data.title}</h1>
                    </div>
                })}
                <div className={`drs-seven-qna ${inView ? "fade-left" : ""}`}>
                    {qna.map((qna, index) => {
                        return <DRSQNA q={qna.data.question} a={qna.data.answer} key={index} qColor={qna.data.questionColor} aColor={qna.data.answerColor} qFont={qna.data.questionFont} aFont={qna.data.answerFont} />
                    })}
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionSeven