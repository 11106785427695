import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionThree.css"
import { useInView } from "react-intersection-observer";
import { db } from '../firebase';

const DeleteReviewsSectionThree = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([])
    const [text, setText] = useState([])

    useEffect(() => {
        db.collection("Service").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })));
        });
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").onSnapshot(snapshot => {
            setText(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })));
        });
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-three" ref={ref}>
                <div className={`drs-three-title ${inView ? "fade-right" : ""}`}>
                    {title.map((title, index) => {
                        return <h1 key={index} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }}>{title.data.title}</h1>
                    })}
                    {text.map((text, index) => {
                        return <p key={index} style={{ color: text.data.textColor, fontFamily: text.data.textFont }}>{text.data.text}</p>
                    })}
                </div>
                <div className={`drs-three-img ${inView ? "fade-up" : ""}`}>
                    <img src="./img/Bewertungen-tel.webp" alt="" />
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionThree