import React, { useEffect, useRef, useState } from 'react'
import "../styles/Footer.css"
import { Link } from 'react-router-dom'
import { db } from '../firebase'
import ReactFontLoader from 'react-font-loader'
import axios from 'axios'

const Footer = () => {

    const [fonts, setFonts] = useState([])
    const [title, setTitle] = useState([])
    const [enterName, setEnterName] = useState("")
    const [enterEmail, setEnterEmail] = useState("")
    const [enterPhone, setEnterPhone] = useState("")
    const [enterMessage, setEnterMessage] = useState("")

    const [successfullMessage, setSuccessfullMessage] = useState([])
    const [successMessage, setSuccessMessage] = useState(false)

    const [loading, setLoading] = useState(false)

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true)

        const email = enterEmail
        const message = enterMessage
        const name = enterName
        const phone = enterPhone


        const subjectRec = `New message from: ${name}`
        const subject = "Vielen Dank für Ihre Kontaktaufnahme"

        try {
            const response = await axios.post('https://deinprofil.onrender.com/receive-contact', {
                subjectRec,
                message,
                email,
                phone,
                name
            });
            setSuccessMessage(true)
            ref1.current.value = ""
            ref2.current.value = ""
            ref3.current.value = ""
            ref4.current.value = ""
            setLoading(false)
        } catch (error) {
            alert('Failed to send email.');
        }
        try {
            const response = await axios.post('https://deinprofil.onrender.com/send-contact', {
                email,
                subject,
            });
        } catch (error) {
            alert('Failed to send email.');
        }
    }

    useEffect(() => {
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection('Footer').onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection('Home').doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").onSnapshot(snapshot => {
            setSuccessfullMessage(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])


    return (
        <div className="footer">
            {fonts.map((font, index) => {
                return <ReactFontLoader url={`${font.data.url}`} key={index} />
            })}
            <div className="contact">
                <div className="lt">
                    <p>Wir beantworten gerne deine Fragen!</p>
                    <p>Jetzt kostenloses Gespräch vereinbaren!</p>
                    <div className="lt-kontakt">
                        {title.map((title, index) => {
                            return <>
                                <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: "3rem", marginBottom: "1rem" }} key={index}>{title.data.title}</h3>
                                <div className="contact-email">
                                    <span style={{ color: title.data.emailColor, fontFamily: title.data.emailFont, fontSize: "1.5rem" }}>{title.data.email}</span>
                                </div>
                                <div className="contact-tel">
                                    <span style={{ color: title.data.phoneColor, fontFamily: title.data.phoneFont, fontSize: "1.5rem" }}>{title.data.phone}</span>
                                </div>
                            </>
                        })}
                    </div>
                </div>
                <form className="contact-form" onSubmit={sendEmail}>
                    <div className="form-name-email">
                        <div className="form-group">
                            <label htmlFor="name">Namen eingeben *</label>
                            <input ref={ref1} type="text" id="name" name="name" onChange={(e) => setEnterName(e.target.value)} required placeholder='Vorname' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">E-Mail-Adresse eingeben *</label>
                            <input ref={ref2} type="email" id="email" name="email" onChange={(e) => setEnterEmail(e.target.value)} required placeholder='Email-Adresse' />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Telefonnummer eingeben *</label>
                        <input ref={ref3} type="tel" id="phone" name="phone" onChange={(e) => setEnterPhone(e.target.value)} required placeholder='Telefon' />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Nachricht eingeben *</label>
                        <textarea ref={ref4} rows={3} id="message" name="message" onChange={(e) => setEnterMessage(e.target.value)} required placeholder='Schreiben Sie Ihre Anfrage' />
                    </div>
                    <div className="contact-form-button">
                        <button className={loading ? "disabled-button-contact" : "contact-form-submit"} type="submit">{loading ? "Wird geladen..." : "EINREICHEN"}</button>
                    </div>
                    {successMessage &&
                        <>
                            {successfullMessage.map((sucMsg, index) => {
                                return <div className="message-sent" key={index} style={{ fontFamily: sucMsg.data.messageFont, fontFamily: sucMsg.data.messageBackground, color: "white" }}><span>{sucMsg.data.text}</span></div>
                            })
                            }
                        </>
                    }
                </form>
            </div>
            <div className="disclaimers">
                <div className="disclaimer">
                    <span>Keine Rechtsberatung</span>
                    <p>Die auf dieser Webseite angebotene Dienstleistung beschränkt sich ausschließlich auf eine Botentätigkeit. Wir möchten ausdrücklich darauf hinweisen, dass unsere Dienste keine Rechtsberatung beinhalten oder ersetzen. Wir leiten Ihr Anliegen lediglich gemäß Ihrer Beschreibung weiter, ohne eine inhaltliche Prüfung oder rechtliche Bewertung vorzunehmen.</p>
                </div>
                <div className="disclaimer">
                    <span>Haftungsausschluss</span>
                    <p>Wir übernehmen keine Haftung für die Richtigkeit, Vollständigkeit oder Aktualität der Informationen, die Sie uns zur Weiterleitung zur Verfügung stellen. Jegliche Haftung für direkte oder indirekte Schäden, die durch die Nutzung unserer Dienste entstehen, wird ausgeschlossen.</p>
                </div>
                <div className="disclaimer">
                    <span>Eigenverantwortung des Nutzers</span>
                    <p>Als Nutzer unserer Dienstleistung sind Sie allein dafür verantwortlich, die rechtliche Relevanz und Korrektheit Ihrer Anliegen zu überprüfen. Bei rechtlichen Fragen oder Unsicherheiten empfehlen wir Ihnen, sich an einen qualifizierten Rechtsberater zu wenden.</p>
                </div>
            </div>
            <div className='gray-footer'>
                <div className="footer-links">
                    <Link to='/datenschutz'>DATENSCHUTZ</Link>
                    <Link to='/impressum'>IMPRESSUM</Link>
                </div>
                <div className="footer-copyright">
                    <p>COPYRIGHT 2024</p>
                    <p>ALLE RECHTE VORBEHALTEN.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer