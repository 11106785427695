import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionFive.css"
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import DoneIcon from '@mui/icons-material/Done';
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionFive = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([]);
    const [stepOne, setStepOne] = useState([]);
    const [stepTwo, setStepTwo] = useState([]);
    const [stepThree, setStepThree] = useState([]);

    useEffect(() => {
        db.collection("Sequence").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").onSnapshot(snapshot => {
            setStepOne(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").onSnapshot(snapshot => {
            setStepTwo(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").onSnapshot(snapshot => {
            setStepThree(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-five" ref={ref}>
                {title.map((title, index) => {
                    return <h1 key={index} className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }} >{title.data.title}</h1>
                })}
                <div className="drs-five-steps">
                    {stepOne.map((step, index) => {
                        return <div key={index} className={`drs-five-step ${inView ? "fade-up-delay1" : ""}`}>
                            <div className="drs-five-step-top">
                                <span><span className="step-num">1</span> <BusinessCenterIcon sx={{ fontSize: 30 }} /></span>
                                <p style={{ fontFamily: step.data.boxTitleFont, color: step.data.boxTitleColor }}>{step.data.title}</p>
                            </div>
                            <p style={{ fontFamily: step.data.boxTextFont, color: step.data.textColor }}>{step.data.text}</p>
                        </div>
                    })}
                    {stepTwo.map((step, index) => {
                        return <div key={index} className={`drs-five-step ${inView ? "fade-up-delay2" : ""}`}>
                            <div className="drs-five-step-top">
                                <span><span className="step-num">2</span> <AutoDeleteIcon sx={{ fontSize: 30 }} /></span>
                                <p style={{ fontFamily: step.data.boxTitleFont, color: step.data.boxTitleColor }}>{step.data.title}</p>
                            </div>
                            <p style={{ fontFamily: step.data.boxTextFont, color: step.data.textColor }}>{step.data.text}</p>
                        </div>
                    })}
                    {stepThree.map((step, index) => {
                        return <div key={index} className={`drs-five-step ${inView ? "fade-up-delay3" : ""}`}>
                            <div className="drs-five-step-top">
                                <span><span className="step-num">3</span> <DoneIcon sx={{ fontSize: 30 }} /></span>
                                <p style={{ fontFamily: step.data.boxTitleFont, color: step.data.boxTitleColor }}>{step.data.title}</p>
                            </div>
                            <p style={{ fontFamily: step.data.boxTextFont, color: step.data.textColor }}>{step.data.text}</p>
                        </div>
                    })}
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionFive