import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionFour.css"
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionFour = ({ refer, refTop }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([]);
    const [packs, setPacks] = useState([])
    const [bulkDiscount, setBulkDiscount] = useState([])

    useEffect(() => {
        db.collection("Price").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").onSnapshot(snapshot => {
            setPacks(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("bulkDiscount").onSnapshot(snapshot => {
            setBulkDiscount(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-four" ref={ref}>
                <div class="pricing-table">
                    {title.map((title, index) => {
                        return <h1 className={inView ? "fade-down" : ""} key={index} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }}>{title.data.title}</h1>
                    })}
                    <div className={`packages ${inView ? "fade-up" : ""}`}>
                        {packs.map((pack, index) => {
                            return <div className="package" style={{ backgroundColor: pack.data.backgroundColor, color: pack.data.textColor }} key={index}>
                                <h2 style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }}>{pack.data.packName}</h2>
                                <span style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }}>{pack.data.revNumb} Bewertungen für je</span>
                                <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="price">{pack.data.price}€</p>
                                <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="old-price">war {pack.data.oldPrice}€</p>
                                <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="savings">{pack.data.savings}% ersparnis</p>
                                <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="description">{pack.data.description}</p>
                            </div>
                        })}
                    </div>
                    {bulkDiscount.map((bulk, index) => {
                        return <div key={index} class={`bulk-discount ${inView ? "fade-up" : ""}`}>
                            <p>{bulk.data.text}</p>
                            <a onClick={() => { refTop.current.scrollIntoView({ behavior: 'smooth' }) }}>{bulk.data.buttonText}</a>
                        </div>
                    })
                    }
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionFour