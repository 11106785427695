import React, { useEffect, useRef, useState } from 'react'
import "../styles/DeleteReviewsSectionTwo.css"
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionTwo = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([])
    const [text, setText] = useState([])

    useEffect(() => {
        db.collection("AboutUs").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").collection("text").onSnapshot(snapshot => {
            setText(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <section ref={refer}>
            <div className='delete-reviews-section-two' ref={ref}>
                {title.map((title, index) => {
                    return <h1 key={index} className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }}>{title.data.title}</h1>
                })}

                {text.map((text, index) => {
                    return <p key={index} className={inView ? "fade-up" : ""} style={{ color: text.data.textColor, fontFamily: text.data.textFont }}>{text.data.text}</p>
                })}
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionTwo