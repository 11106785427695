import React from 'react'
import "../styles/Impressum.css"
import { Link } from 'react-router-dom'

const Impressum = () => {
    return (
        <div className="impressum">
            <div className="impressum-logo" style={{ position: "absolute", top: "0", left: "0", right: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Link to="/" >
                    <img className="impressum-logo-img" style={{ cursor: "pointer" }} src="./img/wldb.com Logo white-yellow.png" alt="" />
                </Link>
            </div>
            <div className="impressum-title">
                <h1>Unser Impressum</h1>
            </div>
            <div className="impressum-text">
                <div className="imrint-text-left">
                    <div className="impressum-text-left-p">
                        <p>Ilija Maksimovic</p>
                        <p>RATING 035</p>
                        <p>Kneza Lazara 55</p>
                        <p>35000 Vinoraca</p>
                    </div>
                    <div className="impressum-text-left-p">
                        <h1>Kontakt</h1>
                        <p>Telefon: +381 600250723</p>
                        <p>E-Mail: info@wirlöschendeinebwertung.com</p>
                    </div>
                    <div className="impressum-text-left-p">
                        <h1>Umsatzsteuer-ID</h1>
                        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</p>
                        <p>114415043</p>
                    </div>
                </div>
                <div className="impressum-text-right">
                    <div className="impressum-text-right-p">
                        <h1>EU-Streitschlichtung</h1>
                        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</p>
                        <p><Link to={"https://ec.europa.eu/consumers/odr/"}>https://ec.europa.eu/consumers/odr/.</Link></p>
                        <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                    </div>
                    <div className="impressum-text-right-p" style={{ marginBottom: "5rem" }}>
                        <h1>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h1>
                        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                        <p>Quelle: <Link to={"https://www.e-recht24.de/impressum-generator.html"}>https://www.e-recht24.de/impressum-generator.html</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Impressum