import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminFooter.css"
import { db } from '../../firebase';

const AdminFooter = () => {

    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [changeEmail, setChangeEmail] = useState("")
    const [changeEmailColor, setChangeEmailColor] = useState("")
    const [changeEmailFont, setChangeEmailFont] = useState("")
    const [changePhone, setChangePhone] = useState("")
    const [changePhoneColor, setChangePhoneColor] = useState("")
    const [changePhoneFont, setChangePhoneFont] = useState("")
    const [fonts, setFonts] = useState([])

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()

    const applyTitle = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            titleFont: changeTitleFont
        })
    }

    const applyEmail = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            email: changeEmail
        })
        ref2.current.value = ""
    }

    const applyEmailColor = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            emailColor: changeEmailColor
        })
    }

    const applyEmailFont = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            emailFont: changeEmailFont
        })
    }

    const applyPhone = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            phone: changePhone
        })
        ref3.current.value = ""
    }

    const applyPhoneColor = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            phoneColor: changePhoneColor
        })
    }

    const applyPhoneFont = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            phoneFont: changePhoneFont
        })
    }

    useEffect(() => {
        db.collection('Footer').onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <div className="footer admin-footer">
            <div className="contact">
                <div className="contact-info">
                    <div className="change-title-wrap admin-footer-title">
                        <div className="change-font">
                            <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                        </div>
                        <div className="change-title">
                            <div className="change-color">
                                <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                                <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                            </div>
                            <label>Change title to:</label>
                            <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                            <button className="apply-button" onClick={applyTitle}>Apply</button>
                        </div>
                        {title.map((title, index) => {
                            return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont }} key={index}>{title.data.title}</h3>
                        })}
                    </div>
                    <div className="change-info">
                        <label htmlFor="">Email font:</label>
                        <div className="change-font admin-footer-change-font">
                            <select name="" id="" onChange={(e) => setChangeEmailFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={applyEmailFont}>Apply</button>
                        </div>
                        <label htmlFor="">Phone font:</label>
                        <div className="change-font admin-footer-change-font">
                            <select name="" id="" onChange={(e) => setChangePhoneFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={applyPhoneFont}>Apply</button>
                        </div>
                        <div className="change-title">
                            <div className="change-color">
                                <label htmlFor="">Email color:</label>
                                <input type="color" defaultValue="#fbbc04" onChange={(e) => setChangeEmailColor(e.target.value)} />
                                <button className="apply-button" onClick={applyEmailColor}>Apply</button>
                            </div>
                            <div className="change-color">
                                <label htmlFor="">Phone color:</label>
                                <input type="color" defaultValue="#fbbc04" onChange={(e) => setChangePhoneColor(e.target.value)} />
                                <button className="apply-button" onClick={applyPhoneColor}>Apply</button>
                            </div>
                            <input ref={ref2} type="text" onChange={(e) => setChangeEmail(e.target.value)} placeholder="Enter email here" />
                            <button className="apply-button" onClick={applyEmail}>Apply</button>
                            <input ref={ref3} type="text" onChange={(e) => setChangePhone(e.target.value)} placeholder="Enter phone here" />
                            <button className="apply-button" onClick={applyPhone}>Apply</button>
                        </div>
                        {title.map((title, index) => {
                            return <>
                                <div className="contact-email">
                                    <span style={{ color: title.data.emailColor, fontFamily: title.data.emailFont }}>{title.data.email}</span>
                                </div>
                                <div className="contact-tel">
                                    <span style={{ color: title.data.phoneColor, fontFamily: title.data.phoneFont }}>{title.data.phone}</span>
                                </div>
                            </>
                        })}
                    </div>
                </div>
            </div>
            <div className="disclaimers">
                <div className="disclaimer">
                    <span>Keine Rechtsberatung</span>
                    <p>Die auf dieser Webseite angebotene Dienstleistung beschränkt sich ausschließlich auf eine Botentätigkeit. Wir möchten ausdrücklich darauf hinweisen, dass unsere Dienste keine Rechtsberatung beinhalten oder ersetzen. Wir leiten Ihr Anliegen lediglich gemäß Ihrer Beschreibung weiter, ohne eine inhaltliche Prüfung oder rechtliche Bewertung vorzunehmen.</p>
                </div>
                <div className="disclaimer">
                    <span>Haftungsausschluss</span>
                    <p>Wir übernehmen keine Haftung für die Richtigkeit, Vollständigkeit oder Aktualität der Informationen, die Sie uns zur Weiterleitung zur Verfügung stellen. Jegliche Haftung für direkte oder indirekte Schäden, die durch die Nutzung unserer Dienste entstehen, wird ausgeschlossen.</p>
                </div>
                <div className="disclaimer">
                    <span>Eigenverantwortung des Nutzers</span>
                    <p>Als Nutzer unserer Dienstleistung sind Sie allein dafür verantwortlich, die rechtliche Relevanz und Korrektheit Ihrer Anliegen zu überprüfen. Bei rechtlichen Fragen oder Unsicherheiten empfehlen wir Ihnen, sich an einen qualifizierten Rechtsberater zu wenden.</p>
                </div>
            </div>
        </div>
    )
}

export default AdminFooter