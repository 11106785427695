import React, { useEffect, useState } from 'react'
import AdminLogIn from './AdminLogIn'
import { auth } from '../../firebase';
import Admin from './Admin';

const AdminServer = () => {

    const [user, setUser] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            setUser(user)
        })
    }, [])

    return (
        <div className="admin-server">
            {user ? <Admin /> : <AdminLogIn />}
        </div>
    )
}

export default AdminServer