import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_firebase_auth_domain,
    projectId: process.env.REACT_APP_firebase_project_id,
    storageBucket: process.env.REACT_APP_firebase_storage_bucket,
    messagingSenderId: process.env.REACT_APP_firebase_messaging_sending_id,
    appId: process.env.REACT_APP_firebase_app_id,
    measurementId: process.env.REACT_APP_firebase_measurement_id
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth()
export const db = app.firestore();
