import React, { useEffect, useState } from 'react'
import "../../styles/admin styles/AdminDRSQNA.css"
import { db } from '../../firebase'

const AdminDRSQNA = ({ q, a, id, qColor, aColor, qFont, aFont }) => {

    const [changeQuestion, setChangeQuestion] = useState("")
    const [changeAnswer, setChangeAnswer] = useState("")
    const [changeQuestionColor, setChangeQuestionColor] = useState("")
    const [changeAnswerColor, setChangeAnswerColor] = useState("")
    const [changeQuestionFont, setChangeQuestionFont] = useState("")
    const [changeAnswerFont, setChangeAnswerFont] = useState("")
    const [fonts, setFonts] = useState([])
    const [openDelete, setOpenDelete] = useState(false)

    const deleteQNA = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").doc(id).delete()
        setOpenDelete(false)
    }

    const applyQuestion = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").doc(id).update({
            question: changeQuestion
        })
    }

    const applyAnswer = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").doc(id).update({
            answer: changeAnswer
        })
    }

    const applyQuestionColor = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").doc(id).update({
            questionColor: changeQuestionColor
        })
    }

    const applyQuestionFont = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").doc(id).update({
            questionFont: changeQuestionFont
        })
    }

    const applyAnswerColor = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").doc(id).update({
            answerColor: changeAnswerColor
        })
    }

    const applyAnswerFont = () => {
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").doc(id).update({
            answerFont: changeAnswerFont
        })
    }

    useEffect(() => {
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])


    return (
        <div className="admin-drsqna">
            <div className="delete-qna" style={{ display: "flex", alignItems: "end", justifyContent: "end", width: "100%", padding: "1rem 1rem 0 0" }}>
                <button className="delete-button" onClick={() => setOpenDelete(true)}>Delete</button>
                {openDelete &&
                    <div className="are-you-sure-to-delete">
                        <span>Are you sure you want to delete this qna?</span>
                        <div className="aystd-buttons">
                            <button onClick={deleteQNA}>Yes</button>
                            <button onClick={() => setOpenDelete(false)}>No</button>
                        </div>
                    </div>
                }
            </div>
            <div className="admin-drs-q">
                <div className="change-font">
                    <select name="" id="" onChange={(e) => setChangeQuestionFont(e.target.value)}>
                        {fonts.map((font, index) => {
                            return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                        })}
                    </select>
                    <button className="apply-button" onClick={applyQuestionFont}>Apply</button>
                </div>
                <div className="change-color">
                    <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeQuestionColor(e.target.value)} />
                    <button className="apply-button" onClick={applyQuestionColor}>Apply</button>
                </div>
                <input type="text" placeholder="Change question to" onChange={(e) => setChangeQuestion(e.target.value)} />
                <button className="apply-button" onClick={applyQuestion}>Apply</button>
                <span style={{ color: qColor, fontFamily: qFont }}>{q}</span>
            </div>
            <div className="admin-drs-a">
                <div className="change-font">
                    <select name="" id="" onChange={(e) => setChangeAnswerFont(e.target.value)}>
                        {fonts.map((font, index) => {
                            return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                        })}
                    </select>
                    <button className="apply-button" onClick={applyAnswerFont}>Apply</button>
                </div>
                <div className="change-color">
                    <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeAnswerColor(e.target.value)} />
                    <button className="apply-button" onClick={applyAnswerColor}>Apply</button>
                </div>
                <textarea rows={3} type="text" placeholder="Change answer to" onChange={(e) => setChangeAnswer(e.target.value)} />
                <button className="apply-button" onClick={applyAnswer}>Apply</button>
                <p style={{ color: aColor, fontFamily: aFont }}>{a}</p>
            </div>
        </div>
    )
}

export default AdminDRSQNA