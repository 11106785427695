import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminTopBar.css"
import { db } from '../../firebase';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const AdminTopBar = () => {

    const [order, setOrder] = useState([])
    const [openNav, setOpenNav] = useState(false)
    const [mobileNav, setMobileNav] = useState(null)
    const [home, setHome] = useState([]);
    const [changeHome, setChangeHome] = useState("")
    const [price, setPrice] = useState([]);
    const [changePrice, setChangePrice] = useState("")
    const [aboutUs, setAboutUs] = useState([]);
    const [changeAboutUs, setChangeAboutUs] = useState("")
    const [whyUs, setWhyUs] = useState([]);
    const [changeWhyUs, setChangeWhyUs] = useState("")
    const [service, setService] = useState([]);
    const [changeService, setChangeService] = useState("")
    const [sequence, setSequence] = useState([]);
    const [changeSequence, setChangeSequence] = useState("")
    const [feedback, setFeedback] = useState([]);
    const [changeFeedback, setChangeFeedback] = useState("")
    const [faq, setFaq] = useState([]);
    const [changeFaq, setChangeFaq] = useState("")

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref7 = useRef()
    const ref8 = useRef()

    const applyHome = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("home").doc(id).set({
            text: changeHome
        })
        ref1.current.value = ""
    }
    const applyPrice = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("price").doc(id).set({
            text: changePrice
        })
        ref2.current.value = ""
    }
    const applyAboutUs = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("about us").doc(id).set({
            text: changeAboutUs
        })
        ref3.current.value = ""
    }
    const applyWhyUs = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("why us").doc(id).set({
            text: changeWhyUs
        })
        ref4.current.value = ""
    }
    const applyService = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("service").doc(id).set({
            text: changeService
        })
        ref5.current.value = ""
    }
    const applySequence = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("sequence").doc(id).set({
            text: changeSequence
        })
        ref6.current.value = ""
    }
    const applyFeedback = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("feedback").doc(id).set({
            text: changeFeedback
        })
        ref7.current.value = ""
    }
    const applyFAQ = (id) => {
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("faq").doc(id).set({
            text: changeFaq
        })
        ref8.current.value = ""
    }

    useEffect(() => {
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("home").onSnapshot(snapshot => {
            setHome(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("price").onSnapshot(snapshot => {
            setPrice(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("about us").onSnapshot(snapshot => {
            setAboutUs(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("why us").onSnapshot(snapshot => {
            setWhyUs(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("service").onSnapshot(snapshot => {
            setService(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("sequence").onSnapshot(snapshot => {
            setSequence(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("feedback").onSnapshot(snapshot => {
            setFeedback(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Nav").doc("ViYnk0aoYIUVkeeQG5Tc").collection("faq").onSnapshot(snapshot => {
            setFaq(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });

        const windowWidth = () => {
            if (window.outerWidth < 561) {
                setMobileNav(true)
            } else {
                setMobileNav(false)
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="admin-top-bar">
            {mobileNav ?
                <div className='admin-top-bar-nav-mob'>
                    <span className="admin-nav-button" onClick={() => { setOpenNav(!openNav) }}>
                        {openNav ? <CloseIcon /> : <MenuIcon />}
                    </span>
                    {openNav &&
                        <ul>
                            {order.map((order, i) => {
                                if (order.data.pageTitle === "Home")
                                    return <>
                                        {home.map((home, index) => {
                                            return <li key={index}>
                                                <input ref={ref1} type="text" placeholder="Change text here" onChange={(e) => setChangeHome(e.target.value)} />
                                                <button className="apply-button" onClick={() => applyHome(home.id)}>Apply</button>
                                                <span>{home.data.text}</span>
                                            </li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Price")
                                    return <>
                                        {price.map((price, index) => {
                                            return <li key={index}>
                                                <input ref={ref2} type="text" placeholder="Change text here" onChange={(e) => setChangePrice(e.target.value)} />
                                                <button className="apply-button" onClick={() => applyPrice(price.id)}>Apply</button>
                                                <span>{price.data.text}</span>
                                            </li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "AboutUs")
                                    return <>
                                        {aboutUs.map((about, index) => {
                                            return <li key={index}>
                                                <input ref={ref3} type="text" placeholder="Change text here" onChange={(e) => setChangeAboutUs(e.target.value)} />
                                                <button className="apply-button" onClick={() => applyAboutUs(about.id)}>Apply</button>
                                                <span>{about.data.text}</span>
                                            </li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "WhyUs")
                                    return <>
                                        {whyUs.map((why, index) => {
                                            return <li key={index}>
                                                <input ref={ref4} type="text" placeholder="Change text here" onChange={(e) => setChangeWhyUs(e.target.value)} />
                                                <button className="apply-button" onClick={() => applyWhyUs(why.id)}>Apply</button>
                                                <span>{why.data.text}</span>
                                            </li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Service")
                                    return <>
                                        {service.map((service, index) => {
                                            return <li key={index}>
                                                <input ref={ref5} type="text" placeholder="Change text here" onChange={(e) => setChangeService(e.target.value)} />
                                                <button className="apply-button" onClick={() => applyService(service.id)}>Apply</button>
                                                <span>{service.data.text}</span>
                                            </li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Sequence")
                                    return <>
                                        {sequence.map((sequence, index) => {
                                            return <li key={index}>
                                                <input ref={ref6} type="text" placeholder="Change text here" onChange={(e) => setChangeSequence(e.target.value)} />
                                                <button className="apply-button" onClick={() => applySequence(sequence.id)}>Apply</button>
                                                <span>{sequence.data.text}</span>
                                            </li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "Feedback")
                                    return <>
                                        {feedback.map((feedback, index) => {
                                            return <li key={index}>
                                                <input ref={ref7} type="text" placeholder="Change text here" onChange={(e) => setChangeFeedback(e.target.value)} />
                                                <button className="apply-button" onClick={() => applyFeedback(feedback.id)}>Apply</button>
                                                <span>{feedback.data.text}</span>
                                            </li>
                                        })}
                                    </>
                                if (order.data.pageTitle === "FAQ")
                                    return <>
                                        {faq.map((faq, index) => {
                                            return <li key={index}>
                                                <input ref={ref8} type="text" placeholder="Change text here" onChange={(e) => setChangeFaq(e.target.value)} />
                                                <button className="apply-button" onClick={() => applyFAQ(faq.id)}>Apply</button>
                                                <span>{faq.data.text}</span>
                                            </li>
                                        })}
                                    </>
                            })}
                        </ul>
                    }
                </div> :
                <ul className="admin-top-bar-nav">
                    {order.map((order, i) => {
                        if (order.data.pageTitle === "Home")
                            return <>
                                {home.map((home, index) => {
                                    return <li key={index}>
                                        <input ref={ref1} type="text" placeholder="Change text here" onChange={(e) => setChangeHome(e.target.value)} />
                                        <button className="apply-button" onClick={() => applyHome(home.id)}>Apply</button>
                                        <span>{home.data.text}</span>
                                    </li>
                                })}
                            </>
                        if (order.data.pageTitle === "Price")
                            return <>
                                {price.map((price, index) => {
                                    return <li key={index}>
                                        <input ref={ref2} type="text" placeholder="Change text here" onChange={(e) => setChangePrice(e.target.value)} />
                                        <button className="apply-button" onClick={() => applyPrice(price.id)}>Apply</button>
                                        <span>{price.data.text}</span>
                                    </li>
                                })}
                            </>
                        if (order.data.pageTitle === "AboutUs")
                            return <>
                                {aboutUs.map((about, index) => {
                                    return <li key={index}>
                                        <input ref={ref3} type="text" placeholder="Change text here" onChange={(e) => setChangeAboutUs(e.target.value)} />
                                        <button className="apply-button" onClick={() => applyAboutUs(about.id)}>Apply</button>
                                        <span>{about.data.text}</span>
                                    </li>
                                })}
                            </>
                        if (order.data.pageTitle === "WhyUs")
                            return <>
                                {whyUs.map((why, index) => {
                                    return <li key={index}>
                                        <input ref={ref4} type="text" placeholder="Change text here" onChange={(e) => setChangeWhyUs(e.target.value)} />
                                        <button className="apply-button" onClick={() => applyWhyUs(why.id)}>Apply</button>
                                        <span>{why.data.text}</span>
                                    </li>
                                })}
                            </>
                        if (order.data.pageTitle === "Service")
                            return <>
                                {service.map((service, index) => {
                                    return <li key={index}>
                                        <input ref={ref5} type="text" placeholder="Change text here" onChange={(e) => setChangeService(e.target.value)} />
                                        <button className="apply-button" onClick={() => applyService(service.id)}>Apply</button>
                                        <span>{service.data.text}</span>
                                    </li>
                                })}
                            </>
                        if (order.data.pageTitle === "Sequence")
                            return <>
                                {sequence.map((sequence, index) => {
                                    return <li key={index}>
                                        <input ref={ref6} type="text" placeholder="Change text here" onChange={(e) => setChangeSequence(e.target.value)} />
                                        <button className="apply-button" onClick={() => applySequence(sequence.id)}>Apply</button>
                                        <span>{sequence.data.text}</span>
                                    </li>
                                })}
                            </>
                        if (order.data.pageTitle === "Feedback")
                            return <>
                                {feedback.map((feedback, index) => {
                                    return <li key={index}>
                                        <input ref={ref7} type="text" placeholder="Change text here" onChange={(e) => setChangeFeedback(e.target.value)} />
                                        <button className="apply-button" onClick={() => applyFeedback(feedback.id)}>Apply</button>
                                        <span>{feedback.data.text}</span>
                                    </li>
                                })}
                            </>
                        if (order.data.pageTitle === "FAQ")
                            return <>
                                {faq.map((faq, index) => {
                                    return <li key={index}>
                                        <input ref={ref8} type="text" placeholder="Change text here" onChange={(e) => setChangeFaq(e.target.value)} />
                                        <button className="apply-button" onClick={() => applyFAQ(faq.id)}>Apply</button>
                                        <span>{faq.data.text}</span>
                                    </li>
                                })}
                            </>
                    })}
                </ul>
            }
        </div>
    )
}

export default AdminTopBar