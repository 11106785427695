import './App.css';
import AdminServer from './components/admin components/AdminServer';
import DeleteReviews from './components/DeleteReviews';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';

const App = () => {

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<DeleteReviews />} />
          <Route path='/admin' element={<AdminServer />} />
          <Route path='/impressum' element={<Impressum />} />
          <Route path='/datenschutz' element={<Datenschutz />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
