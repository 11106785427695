import React, { useEffect, useState } from 'react'
import "../../styles/admin styles/Admin.css"
import { signOut } from 'firebase/auth';
import { auth, db } from '../../firebase';
import AdminSectionOne from './AdminSectionOne';
import AdminSectionTwo from './AdminSectionTwo';
import AdminSectionThree from './AdminSectionThree';
import AdminSectionFour from './AdminSectionFour';
import AdminTopBar from './AdminTopBar';
import { Link } from 'react-router-dom';
import AdminSectionFive from './AdminSectionFive';
import AdminSectionSeven from './AdminSectionSeven';
import AdminSectionSix from './AdminSectionSix';
import AdminSectionEight from './AdminSectionEight';
import AdminFooter from './AdminFooter';

const Admin = () => {

    const [order, setOrder] = useState([])

    const logout = async () => {
        await signOut(auth)
    }

    useEffect(() => {
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <div className="admin">
            <div className="logout">
                <button onClick={logout}>Log Out</button>
                <Link to="/">Back to main page</Link>
            </div>
            <AdminTopBar />
            {order.map((order, index) => {
                if (order.data.pageTitle === "Home")
                    return <>
                        <AdminSectionOne key={index} />
                        <div className="line"></div>
                    </>
                if (order.data.pageTitle === "Price")
                    return <>
                        <AdminSectionFour key={index} />
                        <div className="line"></div>
                    </>
                if (order.data.pageTitle === "AboutUs")
                    return <>
                        <AdminSectionTwo key={index} />
                        <div className="line"></div>
                    </>
                if (order.data.pageTitle === "WhyUs")
                    return <>
                        <AdminSectionEight key={index} />
                        <div className="line"></div>
                    </>
                if (order.data.pageTitle === "Service")
                    return <>
                        <AdminSectionThree key={index} />
                        <div className="line"></div>
                    </>
                if (order.data.pageTitle === "Sequence")
                    return <>
                        <AdminSectionFive key={index} />
                        <div className="line"></div>
                    </>
                if (order.data.pageTitle === "Feedback")
                    return <>
                        <AdminSectionSix key={index} />
                        <div className="line"></div>
                    </>
                if (order.data.pageTitle === "FAQ")
                    return <>
                        <AdminSectionSeven key={index} />
                        <div className="line"></div>
                    </>
            })}
            <AdminFooter />
        </div>
    )
}

export default Admin